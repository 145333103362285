export const schoolName = 'Grundschule Rieneck';
export const schoolPrefix = 'gsr';

export const authURL = 'https://users.' + schoolPrefix + '.grusl.de/';
export const validationURL = authURL + 'validation';
export const loginURL = authURL + 'login';
export const logoutURL = authURL + 'logout';
export const userRolesURL = authURL + 'user-roles';
export const associationsURL = authURL + 'associations';
export const fetchClassURL = associationsURL + '/read/self/key/class';
export const userURL = authURL + 'users';
export const userByRoleURL = userURL + '/by-role';
export const changeURL = authURL + 'change';
export const changeUsernameURL = changeURL + '/username';
export const changePasswordURL = changeURL + '/password';

export const baseURL = 'https://' + schoolPrefix + '.grusl.de/api/';

export const subjectURL = baseURL + 'subjects';
export const classListURL = baseURL + 'classes';
export const classTextURL = baseURL + 'class-text';
export const assignmentURL = baseURL + 'assignments';
export const answerURL = baseURL + 'answers';
export const filesURL = baseURL + 'files';
export const assignmentListURL = assignmentURL + '/list';
export const feedbackURL = baseURL + 'feedback';

export const scheduleURL = baseURL + 'schedules';
export const scheduleExistsURL = scheduleURL + '/exists';

export const userJobURL = baseURL + 'user-job';
export const startJobURL = userJobURL + '/start';
export const pauseJobURL = userJobURL + '/pause';
export const resumeJobURL = userJobURL + '/resume';

export const getSimplePasswordURL = baseURL + 'generate/password/simple';
export const getRegularPasswordURL = baseURL + 'generate/password/regular';
export const generatePDFURL = baseURL + 'generate/userpdf';

export const teacherRole = 'Lehrkraft';
export const studentRole = 'Schüler';
export const adminRole = 'Administrator';

export const noError = 'none';

export const pollDelayShort = 1500;
export const pollDelayMedium = 3000;
export const pollDelayLong = 5000;

export const makeAssignmentFileURL = (assignmentID: string, filename: string): string => {
    return filesURL + '/assignments/' + assignmentID + '/files/' + filename;
};

export const makeAnswerFileURL = (assignmentID: string, userID: string, filename: string): string => {
    return filesURL + '/answers/' + assignmentID + '/' + userID + '/' + filename;
};

export const makeFeedbackFileURL = (assignmentID: string, userID: string, filename: string): string => {
    return filesURL + '/feedback/' + assignmentID + '/' + userID + '/' + filename;
};

export const makePDFURL = (code: string) => {
    return filesURL + '/userpdf/' + code;
};
